<template>
  <div class="content">
    <div
      @click="checkTitle(item)"
      v-for="item in titleList"
      :key="item.id"
      :class="item.id == checkId ? 'content-active' : 'content-title'"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checkId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      titleList: [
        {
          id: 1,
          name: "剧院动态",
          url: "/ArtsInformation/dynamic",
          isChild: true,
        },
        {
          id: 2,
          name: "剧院公告",
          url: "/ArtsInformation/announcement",
          isChild: true,
        },
        {
          id: 3,
          name: "剧目推介",
          url: "/ArtsInformation/recommend",
          isChild: true,
        },
        {
          id: 4,
          name: "人艺活动",
          url: "/ArtsInformation/activity",
          isChild: false,
        },
      ],
    };
  },
  methods: {
    checkTitle(item) {
      if (this.checkId !== item.id) {
        this.listId = item.id;
        this.$router.push(item.url);
      }
    },
  },
};
</script>
<style scoped lang="less">
.content {
  width: 100%;
  height: 1.2rem;
  border-bottom: 0.0133rem solid #e4e6eb;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  line-height: 1.2rem;
  display: flex;
  justify-content: space-around;
  .content-title {
    font-size: 0.4rem;
  }
  .content-active {
    color: #f9680d;
    font-size: 0.4rem;
  }
}
</style>
