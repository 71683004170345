<template>
  <div class="all-box">
    <topMenu :checkId="4"></topMenu>
    <div class="found">
      <div class="top-box">
        <div class="found-left">
          <div class="left-bg"></div>
          <div class="left-title">人艺活动</div>
        </div>
      </div>
      <div
        @click="toDetail(item.id)"
        v-for="item in personList"
        :key="item.id"
        class="list-box"
      >
        <img :src="item.image" :onerror="defaultImg" class="list-left" />
        <div class="list-right">
          <div class="title">{{ item.title }}</div>
          <div class="tag">{{ item.tag }}</div>
          <div class="time">{{ item.createTime }}</div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import topMenu from "./topMenu.vue";
import { getByLevel, portalHit } from "@/service/ArtsInformation";
export default {
  components: {
    topMenu,
  },
  data() {
    return {
      defaultImg:
        'this.src="' + require("../../../assets/imgs/noData.jpg") + '"',
      form: {
        level1: "NEWS",
        level2: "RYHD",
        orderDirection: "1",
      },
      pager: {
        pageNo: 1,
        pageSize: 9999,
        totalPage: 0,
      },
      personList: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const params = { ...this.form };
      const res = await getByLevel({
        ...this.pager,
        condition: params,
        isShow: 1,
      });
      this.personList = res.data.data;
      this.pager = {
        pageNo: res.data.pageNo,
        totalPage: res.data.totalPage,
        pageSize: res.data.pageSize,
      };
    },
    toDetail(id) {
      portalHit(id);
      this.$router.push({
        path: "/ArtsInformation/detail",
        query: {
          id: id,
          page: "4",
        },
      });
    },
  },
};
</script>
<style scoped lang="less">
.all-box {
  .found {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-top: 0.1733rem;
    .top-box {
      display: flex;
      justify-content: space-between;
      margin-left: 0.4rem;
      .found-left {
        display: flex;
        justify-content: space-between;
        .left-bg {
          background-image: url("../../../assets/imgs/introduction.png");
          width: 0.24rem;
          height: 0.4533rem;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .left-title {
          font-size: 0.4533rem;
          font-weight: 800;
          margin-left: 0.2133rem;
        }
      }
    }
    .list-box {
      width: 9.2rem;
      height: 2.4267rem;
      margin: 0.5333rem 0.3rem 0.5333rem 0.3rem;
      display: flex;
      .list-left {
        height: 2.4267rem;
        width: 3.84rem;
        border-radius: 0.16rem;
      }
      .list-right {
        height: 2.4267rem;
        width: 5.0933rem;
        margin-left: 0.2667rem;
        .title {
          font-size: 0.3467rem;
          font-weight: 500;
          color: #212122;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .tag {
          margin-top: 0.2667rem;
          width: 1.28rem;
          height: 0.3733rem;
          background: #f8d39f;
          color: #212122;
          font-weight: 400;
          line-height: 0.3733rem;
          font-size: 0.2667rem;
          text-align: center;
        }
        .time {
          margin-top: 0.4933rem;
          color: #909399;
          font-size: 0.2667rem;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
